import React from 'react';

const AlbumDisplay2 = () => {
  const youtubeIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"/>
      <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"/>
    </svg>
  );

  const spotifyIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <circle cx="12" cy="12" r="10"/>
      <path d="M8 14.5c2.5-1 5.5-1 8 0"/>
      <path d="M7 11.5c3.5-1 6.5-1 10 0"/>
      <path d="M6 8.5c4.5-1 7.5-1 12 0"/>
    </svg>
  );

  const appleIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M12 20.94c1.5 0 2.75 1.06 4 1.06 3 0 6-8 6-12.22A4.91 4.91 0 0 0 17 5c-2.22 0-4 1.44-5 2-1-.56-2.78-2-5-2a4.9 4.9 0 0 0-5 4.78C2 14 5 22 8 22c1.25 0 2.5-1.06 4-1.06Z"/>
      <path d="M10 2c1 .5 2 2 2 5"/>
    </svg>
  );

  const amazonIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M21 8v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2Z"/>
      <path d="M3 8h18"/>
      <path d="m12 12 4 4"/>
      <path d="m12 12-4 4"/>
    </svg>
  );

  return (
    <div className="album-container-home">
      <div className="album-wrapper-home">
        <div className="album-image-section-home">
          <img
            src="https://images.unsplash.com/photo-1619983081563-430f63602796?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D%q=100"
            alt="The Great Commission Album Cover"
            className="album-image-home"
          />
        </div>
        
        <div className="album-info-section-home">
          <div className="album-header-home">
            <h1>UPCOMING ALBUM 2</h1>
            <h2>MASTER OF THE UNIVERSE – OLORI AYE</h2>
            <p className="album-year-home">2025</p>
            <p className="album-description-home">
            The Album "Master of the Universe (Olori Aye)" celebrates God’s supreme authority and majesty. "Olori Aye," which translates to "Ruler of the Earth" in Yoruba, reflects themes of worship, reverence, and awe for God's omnipotence. The lyrics highlight God's power to make mountains crumble, defeat enemies, and command the universe, affirming His role as the ultimate creator and sustainer.
            The song seeks to draw listeners closer to God's presence, emphasizing His unparalleled greatness and ability to provide peace and victory.
            </p>
            <p className="album-description-home">The song seeks to draw listeners closer to God's presence, emphasizing His unparalleled greatness and ability to provide peace and victory.</p>
            <p>But in these last days He has spoken to us by His Son, whom He appointed heir of all things, and through whom He made the universe. </p>
            <p style={{textAlign: 'right', marginRight: '2rem'}}>—Hebrew  1:2</p>
          </div>
        
          <div className="platform-buttons">
            <button className="platform-button">
              {youtubeIcon}
              <span>YouTube</span>
            </button>
            <button className="platform-button">
              {appleIcon}
              <span>Apple Music</span>
            </button>
            <button className="platform-button">
              {spotifyIcon}
              <span>Spotify</span>
            </button>
            <button className="platform-button">
              {amazonIcon}
              <span>Amazon</span>
            </button>
          </div>

          <button className="all-albums-button">
            ALL ALBUMS
          </button>
        </div>
      </div>
      
      <style jsx>{`
        .album-container-home {
          min-height: 100vh;
          background-color: #f8f9fa;
          padding: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top:0px
        }

        .album-wrapper-home {
          background: white;
          border-radius: 20px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
          max-width: 1200px;
          width: 100%;
          margin: 0 auto;
          padding: 3rem;
          display: grid;
          gap: 3rem;
          grid-template-columns: 1fr 1fr;
        }

        .album-image-section-home {
          position: relative;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
          height:700px,
        }

        .album-image-section-home:hover {
          transform: scale(1.02);
        }

        .album-image-home {
          width: 100%;
          height: auto;
          display: block;
          object-fit: cover;
        }

        .album-info-section-home {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 2rem;
        }

        .album-header-home h1 {
          font-size: 2.5rem;
          font-weight: 700;
          color: #1a1a1a;
          margin: 0 0 0.5rem 0;
          line-height: 1.2;
        }

        .album-year-home {
          font-size: 1.25rem;
          color: #666;
          margin: 0 0 1rem 0;
          font-weight: 500;
        }

        .album-description-home {
          font-size: 1.1rem;
          line-height: 1.8;
          color: #4a4a4a;
          margin: 0;
        }

        .platform-buttons {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
        }

        .platform-button {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.8rem 1.5rem;
          border: 2px solid #eaeaea;
          border-radius: 50px;
          background-color: white;
          color: #333;
          cursor: pointer;
          font-size: 1rem;
          font-weight: 500;
          transition: all 0.3s ease;
        }

        .platform-button:hover {
          background-color: #f8f9fa;
          border-color: #333;
          transform: translateY(-2px);
        }

        .all-albums-button {
          padding: 1rem 2rem;
          background-color: #333;
          color: white;
          border: none;
          border-radius: 50px;
          cursor: pointer;
          font-size: 1rem;
          font-weight: 500;
          transition: all 0.3s ease;
          align-self: flex-start;
        }

        .all-albums-button:hover {
          background-color: #1a1a1a;
          transform: translateY(-2px);
        }

        @media (max-width: 768px) {
          .album-wrapper-home {
            grid-template-columns: 1fr;
            padding: 1.5rem;
          }
          .album-image-home {
            height:350px; 
            }
          .album-header-home h1 {
            font-size: 2rem;
          }

          .platform-buttons {
            justify-content: center;
          }

          .all-albums-button {
            width: 100%;
            text-align: center;
          }

          .album-container-home {
            padding: 1rem;
          }
        }
      `}</style>
    </div>
  );
};

export default AlbumDisplay2;