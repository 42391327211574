import React, { useState } from 'react';
import BlogMainContent from './BlogMainContent';

// SVG Icons components for better organization
const DownloadIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2"
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="7 10 12 15 17 10" />
    <line x1="12" y1="15" x2="12" y2="3" />
  </svg>
);

const ExternalLinkIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2"
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
    <polyline points="15 3 21 3 21 9" />
    <line x1="10" y1="14" x2="21" y2="3" />
  </svg>
);

const ChevronRightIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2"
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <polyline points="9 18 15 12 9 6" />
  </svg>
);

const BlogCard = ({ post, onDownload }) => (
  <div className="blog-card">
    <div className="image-container">
      <img 
        src={post.image} 
        alt={post.title}
        className="card-image"
      />
      <div className="image-overlay" />
    </div>
    
    <div className="card-content">
      <p className="category">{post.category}</p>
      <h2 className="title">{post.title}</h2>
      {post.subtitle && (
        <p className="subtitle">{post.subtitle}</p>
      )}
    </div>

    <div className="card-footer-publication">
      <button
        onClick={() => onDownload(post.downloadUrl)}
        className="button download-button"
      >
        <DownloadIcon />
        <span>Download PDF</span>
      </button>
      
      <button className="button-publication read-more-button-publication">
        <ExternalLinkIcon />
        <span>Read More</span>
      </button>
    </div>
  </div>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="pagination">
    {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
      <button
        key={number}
        onClick={() => onPageChange(number)}
        className={`page-button ${currentPage === number ? 'active' : ''}`}
      >
        {number}
      </button>
    ))}
    {currentPage < totalPages && (
      <button
        onClick={() => onPageChange(currentPage + 1)}
        className="page-button next-button"
      >
        <span>Next</span>
        <ChevronRightIcon />
      </button>
    )}
  </div>
);

const BlogGrid = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const blogPosts = [
    {
      id: 1,
      title: "Worthy of My Praise Alarm",
      category: "The Great Commission",
      subtitle: "Repentance & Yieldedness",
      image: "https://images.unsplash.com/photo-1499750310107-5fef28a66643?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      downloadUrl: "/downloads/worthy-praise-alarm.pdf"
    },
    {
      id: 2,
      title: "The Great Commission",
      category: "New Album",
      subtitle: "Repentance & Yieldedness",
      image: "https://images.unsplash.com/photo-1499750310107-5fef28a66643?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      downloadUrl: "/downloads/great-commission.pdf"
    },
    {
      id: 3,
      title: "A Call to Prayer II",
      subtitle: "Repentance & Yieldedness",
      category: "The Word",
      image: "https://images.unsplash.com/photo-1499750310107-5fef28a66643?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      downloadUrl: "/downloads/call-to-prayer.pdf"
    },
    // Add more blog posts as needed
  ];

  const totalPages = Math.ceil(blogPosts.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogPosts.slice(indexOfFirstItem, indexOfLastItem);

  const handleDownload = (url) => {
    console.log(`Downloading from: ${url}`);
    // Implement download logic here
  };

  return (
    <div className="pb-20" >
    <BlogMainContent imageUrl='https://images.unsplash.com/photo-1434030216411-0b793f4b4173?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&q=100' />
    <div className="blog-container">
      <div className="blog-grid">
        {currentItems.map((post) => (
          <BlogCard 
            key={post.id} 
            post={post} 
            onDownload={handleDownload}
          />
        ))}
      </div>

      <Pagination 
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
    </div>
  );
};

export default BlogGrid;