import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// Styled Components
const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden; /* Ensures only one video is visible at a time */
  position: relative;
`;

const Title = styled.h1`
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-top:10px
`;

const VideoList = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; /* Ensure it matches the container width */
`;

const VideoItem = styled.div`
  flex: 0 0 100%; /* Each video takes up 100% of the visible area */
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const Pagination = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
`;

const Dot = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: ${(props) => (props.active ? "#555" : "#ddd")};
  cursor: pointer;
  transition: background-color 0.3s;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  ${(props) => (props.direction === "left" ? "left: 10px;" : "right: 10px;")}
`;

// Main Carousel Component
const YouTubeCarousel = ({ videos, title }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRefs = useRef([]);

  const nextSlide = () => {
    if (!isPlaying) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
    }
  };

  const prevSlide = () => {
    if (!isPlaying) {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + videos.length) % videos.length
      );
    }
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const handlePlayerStateChange = (event, index) => {
    if (event.data === 1) {
      // Video is playing
      setIsPlaying(true);
    } else if (event.data === 0 || event.data === 2) {
      // Video has ended or is paused
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [isPlaying]);

  useEffect(() => {
    videoRefs.current.forEach((player, index) => {
      if (player) {
        player.addEventListener("onStateChange", (event) =>
          handlePlayerStateChange(event, index)
        );
      }
    });
  }, []);

  return (
    <CarouselWrapper>
      <Title>{title}</Title>
      <ArrowButton direction="left" onClick={prevSlide}>
        &#8249;
      </ArrowButton>
      <VideoList style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {videos.map((video, index) => (
          <VideoItem key={index}>
            <iframe
              ref={(el) => (videoRefs.current[index] = el)}
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${video}?enablejsapi=1`}
              title={`YouTube video ${index}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </VideoItem>
        ))}
      </VideoList>
      <ArrowButton direction="right" onClick={nextSlide}>
        &#8250;
      </ArrowButton>
      <Pagination>
        {videos.map((_, index) => (
          <Dot
            key={index}
            active={currentIndex === index}
            onClick={() => goToSlide(index)}
          />
        ))}
      </Pagination>
    </CarouselWrapper>
  );
};


export default YouTubeCarousel;
