import React from 'react';
import { Container } from 'react-bootstrap';

const MusicMainContent = ({ imageUrl }) => {
  return (
    <Container fluid className="music-container">
      <img className="bg-img-music" src={imageUrl} alt="Background" />
      <div className="content-wrapper">
        <figcaption className='figcaption-music'>
          <div className="main-title">VOKIM Music</div>
          <p className="subtext">
            VOKIM is a gospel band dedicated to spreading the gospel through heartfelt worship and praise, responding to God's movement across the earth in these transformative times.
          </p>
        </figcaption> 
      </div> 
      
    </Container>
  );
};

export default MusicMainContent;
