import React, { useState } from 'react';
import MessageMainContent from './MessageMainContent';

const videos = [
  { id: 1, title: "God's Love Message", embedId: "ccWdx6KWAxE" },
  { id: 2, title: "Strength in Faith", embedId: "ccWdx6KWAxE" },
  { id: 3, title: "Walking in Hope", embedId: "ccWdx6KWAxE" },
];

const messages = [
  {
    id: 1,
    title: "God's Unfailing Love",
    verse: "Romans 5:8",
    verseText: "But God demonstrates his own love for us in this: While we were still sinners, Christ died for us.",
    message: "No matter where we are in life, God's love meets us where we are. Let this week be a reminder that His love is unending and is always there to lift us up, heal, and restore."
  },
  {
    id: 2,
    title: "Strength in the Lord",
    verse: "Psalm 28:7",
    verseText: "The Lord is my strength and my shield; my heart trusts in him, and he helps me.",
    message: "When life feels overwhelming, remember that God is your strength. Lean on Him, and He will renew your strength and guide you through every challenge."
  },
  {
    id: 3,
    title: "The Power of Faith",
    verse: "2 Corinthians 5:7",
    verseText: "For we live by faith, not by sight.",
    message: "Faith opens the door to hope and miracles. Even when the path ahead seems uncertain, walk in faith, trusting that God is guiding your steps."
  },
  {
    id: 4,
    title: "Hope in God's Promises",
    verse: "Jeremiah 29:11",
    verseText: "For I know the plans I have for you, declares the Lord, plans to prosper you and not to harm you, plans to give you hope and a future.",
    message: "God's promises are true and everlasting. No matter what you face, hold onto the hope that He has a beautiful future planned for you."
  }
];

const GospelHub = () => {
  const [hoveredMessage, setHoveredMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const getCSRFToken = async () => {
    try {
      const response = await fetch('/csrf/', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch CSRF token: ${response.statusText}`);
      }
      const data = await response.json();
      return data.csrfToken;
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      throw error;
    }
  };
  
  
  const handleSubscribe = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
  
    if (!email) {
      setError('Please enter your email address');
      return;
    }
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const csrfToken = await getCSRFToken();
      const response = await fetch('/api/subscribe/', {  // Note the /api/ prefix
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        credentials: 'include',
        body: JSON.stringify({ email }),
      });
  
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Subscription failed');
      }
  
      setSuccess(true);
      setEmail('');
    } catch (err) {
      setError(err.message || 'Failed to subscribe. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container-message">
      <MessageMainContent imageUrl='https://images.unsplash.com/photo-1505855602532-fa8341a13055?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&q=100' />
      <div className="message-wrapper">

      {/* Header and sections */}
      {/* <header className="header">
        {/* <h1 className="title">Welcome to Vokim Messages</h1>
        <p className="subtitle">
          A place for inspiration, reflection, and encouragement. Here, we share powerful messages
          from the Word of God to uplift, guide, and strengthen you on your spiritual journey.
        </p> */}
      {/* </header> */}

      {/* Messages */}
      <section className="section">
        <h2 className="section-title">Messages</h2>
        <div>
          {messages.map((message) => (
            <div
              key={message.id}
              className={`message-card ${hoveredMessage === message.id ? 'message-card-hover' : ''}`}
              onMouseEnter={() => setHoveredMessage(message.id)}
              onMouseLeave={() => setHoveredMessage(null)}
            >
              <h3 className="message-title">{message.title}</h3>
              <p className="verse">"{message.verseText}" — {message.verse}</p>
              <p className="message">{message.message}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Videos */}
      <section className="section">
        <h2 className="section-title">Preachers Corner Videos</h2>
        <div className="videos-grid">
          {videos.map((video) => (
            <div key={video.id} className="video-card">
              <div className="video-container">
                <iframe
                  className="iframe"
                  src={`https://www.youtube.com/embed/${video.embedId}`}
                  title={video.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="video-title">{video.title}</div>
            </div>
          ))}
        </div>
      </section>

      {/* Reflections */}
      <section className="section reflections-section">
        <h2 className="section-title">Reflections & Insights</h2>
        <p>
          The Gospel Hub is designed to bring meaningful reflections on God's Word. Take time to
          meditate on these messages, engage in prayer, and let the Word be a lamp unto your feet.
        </p>
      </section>

      {/* Subscription Form */}
      <section className="section">
        <h2 className="section-title">Subscribe to Our Weekly Newsletter</h2>
        <form onSubmit={handleSubscribe}>
          <input
            type="email"
            className="input"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
          <button className="button" type="submit" disabled={isLoading}>
            {isLoading ? 'Subscribing...' : 'Subscribe'}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">You have successfully subscribed!</p>}
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 Vokim.ca All rights reserved.</p>
      </footer>
      </div>
    </div>
  );
};

export default GospelHub;
