import React from 'react';
import MusicMainContent from './MusicMainContent';
import { useMediaQuery } from '@mui/material';

const VokMusic = () => {
  const spotifyPlaylist = (
    <div className="spotify-playlist">
      <iframe
        src="https://open.spotify.com/embed/track/7Ee6XgP8EHKDhTMYLIndu9?utm_source=generator"
        width="100%"
        frameBorder="0"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  );
{/* <iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/4LZtDy62wDvQ4o8JB4UrcR?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> */}
  const isMobile = useMediaQuery('(max-width:768px)');
  return (
    <div>
      <MusicMainContent imageUrl='https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&q=100' />
      <div className="album-container">
        <div className="album-wrapper">
          <div className="album-image-section">
            <img
              src="https://images.unsplash.com/photo-1619983081563-430f63602796?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D%q=100"
              alt="The Great Commission Album Cover"
              className="album-image"
            />
          </div>

          <div className="album-info-section">
            <div className="album-header">
              <h1>Upcoming Album: AMOPE WA – We Offer PRAISE!</h1>
              <p className="album-year">2025</p>
              <p className="album-description-home">
            The Album "Master of the Universe (Olori Aye)" celebrates God’s supreme authority and majesty. "Olori Aye," which translates to "Ruler of the Earth" in Yoruba, reflects themes of worship, reverence, and awe for God's omnipotence. The lyrics highlight God's power to make mountains crumble, defeat enemies, and command the universe, affirming His role as the ultimate creator and sustainer.
            The song seeks to draw listeners closer to God's presence, emphasizing His unparalleled greatness and ability to provide peace and victory.
            </p>
            <p className="album-description-home">The song seeks to draw listeners closer to God's presence, emphasizing His unparalleled greatness and ability to provide peace and victory.</p>
            <p>But in these last days He has spoken to us by His Son, whom He appointed heir of all things, and through whom He made the universe. </p>
            <p style={{textAlign: 'right', marginRight: '2rem'}}>—Hebrew  1:2</p>
              
            </div>

            {spotifyPlaylist}
          </div>
        </div>
      </div>
      <div className="album-container" style={{marginTop:isMobile ? '10px' : '-10px'}}>
        <div className="album-wrapper">
          <div className="album-image-section">
            <img
              src="https://images.unsplash.com/photo-1619983081563-430f63602796?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D%q=100"
              alt="The Great Commission Album Cover"
              className="album-image"
            />
          </div>

          <div className="album-info-section">
            <div className="album-header">
              <h1>Upcoming Album: MASTER OF THE UNIVERSE – OLORI AYE</h1>
              <p className="album-year">2025</p>
              <p className="album-description-home">
            This album announces God's focus on thanksgiving  - Praising God for His grace and the countless blessings in life. The title roughly translates to "Our Gratitude," emphasizing giving thanks to God. The lyrics reflect themes of thanksgiving, divine grace, and redemption, acknowledging how God's mercy and intervention have transformed lives. It's a song of deep reflection and appreciation for God's unfailing love and support during challenging times.
            </p>
            <p>In the song,  reflecting testimonies of overcoming challenges, attributing all victories to God's grace rather than personal effort. "Amope wa" serves as a repeated call to gratitude and worship. </p>
            <p style={{textAlign: 'right', marginRight: '4rem'}}>— Ephesians 5:19-20</p>
            </div>

            {spotifyPlaylist}
          </div>
        </div>
      </div>

      <div className="album-container" style={{marginTop:isMobile ? '10px' : '10px'}}>
        <div className="album-wrapper">
          <div className="album-image-section">
            <img
              src="https://images.unsplash.com/photo-1619983081563-430f63602796?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D%q=100"
              alt="The Great Commission Album Cover"
              className="album-image"
            />
          </div>
          <div className="album-info-section">
            <div className="album-header">
              <h1>Upcoming Album: Testimony Overflow – Jehovah has done me well!</h1>
              <p className="album-year">2025</p>
              <p className="album-description-home">
            The Album "Testimony Overflow" is  representing a celebration of God's work  our life, where  our testimony (story) of faith overflows with joy, praise, and thanksgiving. This is inspiring others to witness the goodness of God. Uplifting and celebratory, encouraging others to reflect on their own blessings
            </p>
            <p className="album-description-home">Tis is is a proclamation of God's goodness and faithfulness, through God's help, we have received far more than we could have hoped for or imagined, leading to an overflow of gratitude and praise. It reflects a heart full of appreciation for God's blessings and intervention in their life.
            </p>
            <p>Now to him who is able to do immeasurably more than all we ask or imagine, according to his power that is at work within us
            </p>
            <p style={{textAlign: 'right', marginRight: '2rem'}}>—Ephesians 3:20</p>
            </div>
            {spotifyPlaylist}
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default VokMusic;