import React from 'react';
import { Container } from 'react-bootstrap';

const BlogMainContent = ({ imageUrl }) => {
  return (
    <Container fluid className="main-container-1">
      <figure>
        <img className="bg-img-blog-pub img-fluid" src={imageUrl} alt="Bg image" />
        <figcaption className="figcaption-blog-pub">
          VOK Publications
        </figcaption>
      </figure>
    </Container>
  );
};

export default BlogMainContent;
