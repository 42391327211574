import React from 'react';
import { Container } from 'react-bootstrap';

const AboutMainContent = ({ imageUrl }) => {
  return (
    <Container fluid className="about-container">
    <img className="bg-img-about" src={imageUrl} alt="Background" />
    <div className="content-wrapper">
      <figcaption className='figcaption-about'>
        <div className="main-title">ABOUT US</div>
        {/* <div className="main-title">Expanding His Kingdom: Exalting Jesus</div> */}
        {/* <p className="subtext">
          VOKIM is a gospel band dedicated to spreading the gospel through heartfelt worship and praise, responding to God's movement across the earth in these transformative times.
        </p> */}
      </figcaption>
    </div>
  </Container>
  );
};

export default AboutMainContent;
