import React from 'react';
import MainContent from './Home/MainContent';
import Quote from './Home/Quote&Title';
import AlbumDisplay from './Home/AlbumInfo';
import AlbumDisplay2 from './Home/AlbumInfo2';
import AlbumDisplay3 from './Home/AlbumInfo3';
import YouTubeCarousel from './Home/VideoCarousel';
import SpecialEvent from './Home/SpecialEvent';

const Home = () => {
  const videos = ['kjAeoFEbEL4', 'kjAeoFEbEL4', 'kjAeoFEbEL4'];
  const imageUrl="/static/Band_Image.jpg"
  return (
    <div className="pb-4">
      <MainContent imageUrl={imageUrl}/>
      <Quote />
      <SpecialEvent /> 
      <AlbumDisplay />
      <AlbumDisplay2 />
      <AlbumDisplay3 />
      <YouTubeCarousel videos={videos} title="Worship Videos"/> 
    </div>
  );
};

export default Home;