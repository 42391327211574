import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const MainContent = ({ imageUrl }) => {
  const scrollToNextSection = () => {
    const nextSection = document.getElementById('next-section');
    if (nextSection) {
      // Calculate the position with an offset
      const offset = 100; // The offset value you want (e.g., 100px from the top)
      const position = nextSection.getBoundingClientRect().top + window.pageYOffset - offset;
  
      // Scroll to the desired position
      window.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Container fluid className="main-container">
      <img className="bg-img1" src={imageUrl} alt="Background" />
      <div className="content-wrapper">
        <figcaption>
          <div className="main-title">Raising Voices</div>
          <div className="main-title">Expanding His Kingdom: Exalting Jesus</div>
          <p className="subtext">
            VOKIM is a gospel band dedicated to spreading the gospel through heartfelt worship and praise, responding to God's movement across the earth in these transformative times.
          </p>
        </figcaption>
      </div>
      <FontAwesomeIcon
        icon={faChevronDown}
        className="arrow-icon"
        onClick={scrollToNextSection}
      />
    </Container>
  );
};

export default MainContent;