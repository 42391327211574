import React from 'react';
import BookingForm  from './JoinVokim/join';
const JoinLayout = () => {
  // const imageUrl="https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&q=100"
  return (
    <div className="pb-0">
      <BookingForm/>
    </div>
  );
};

export default JoinLayout;