import React, { useState } from 'react';
import { Facebook, Youtube, Instagram, Loader2, CheckCircle, XCircle } from 'lucide-react';

const Alert = ({ children, type }) => (
  <div className={`alert ${type}`}>
    {children}
  </div>
);

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    source: '',
    subject: '',
    message: ''
  });

  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: null
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, success: false, error: null });

    try {
      const response = await fetch('/api/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      setStatus({ loading: false, success: true, error: null });
      setFormData({
        name: '',
        email: '',
        phone: '',
        source: '',
        subject: '',
        message: ''
      });

      setTimeout(() => {
        setStatus(prev => ({ ...prev, success: false }));
      }, 5000);

    } catch (error) {
      setStatus({ loading: false, success: false, error: error.message });
      
      setTimeout(() => {
        setStatus(prev => ({ ...prev, error: null }));
      }, 5000);
    }
  };

  return (
    <div id="contact-page">
    <div className="contact-container">
      <div className="contact-wrapper">
        {/* Contact Details Section */}
        <div className="contact-details">
          <h2>CONTACT DETAILS</h2>
          <div className="contact-info">
            <p>
              <span>Email:</span>
              <a href="mailto:info@vokim.ca">info@vokim.ca</a>
            </p>
            <p>
              <span>Phone:</span>
              <a href="tel:780 807 2238">+1 780 807 2238</a>
            </p>
            <p>
              <span>Mobile:</span>
              <a href="tel:587 664 2629">+1 587 664 2629</a>
            </p>
          </div>
          
          <div className="social-icons">
            <a href="#" aria-label="Facebook">
              <Facebook size={24} />
            </a>
            <a href="#" aria-label="Youtube">
              <Youtube size={24} />
            </a>
            <a href="#" aria-label="Instagram">
              <Instagram size={24} />
            </a>
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="form-section">
          <h2>CONTACT US</h2>
          <p className="form-description">
            Drop Your Messages here And We Will Get Back To You As Soon As Possible.
          </p>
          
          {/* Status Messages */}
          {status.success && (
            <Alert type="success">
              <CheckCircle size={16} />
              <span>Message sent successfully! We'll get back to you soon.</span>
            </Alert>
          )}
          
          {status.error && (
            <Alert type="error">
              <XCircle size={16} />
              <span>Failed to send message. Please try again later.</span>
            </Alert>
          )}
          
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="Name *"
                required
                value={formData.name}
                onChange={handleChange}
                disabled={status.loading}
              />
            </div>
            
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Email *"
                required
                value={formData.email}
                onChange={handleChange}
                disabled={status.loading}
              />
            </div>
            
            <div className="form-group">
              <input
                type="tel"
                name="phone"
                placeholder="Phone *"
                required
                value={formData.phone}
                onChange={handleChange}
                disabled={status.loading}
              />
            </div>
            
            <div className="form-group">
              <select
                name="source"
                value={formData.source}
                onChange={handleChange}
                disabled={status.loading}
              >
                <option value="">How did you hear about us?</option>
                <option value="social">Social Media</option>
                <option value="friend">Friend</option>
                <option value="search">Search Engine</option>
                <option value="other">Other</option>
              </select>
            </div>
            
            <div className="form-group">
              <input
                type="text"
                name="subject"
                placeholder="Subject *"
                required
                value={formData.subject}
                onChange={handleChange}
                disabled={status.loading}
              />
            </div>
            
            <div className="form-group">
              <textarea
                name="message"
                placeholder="Message *"
                rows="6"
                required
                value={formData.message}
                onChange={handleChange}
                disabled={status.loading}
              ></textarea>
            </div>
            
            <button
              type="submit"
              disabled={status.loading}
              className={`submit-button ${status.loading ? 'loading' : ''}`}
            >
              {status.loading ? (
                <>
                  <Loader2 size={16} className="spinner" />
                  <span>Sending...</span>
                </>
              ) : (
                'Submit Now'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ContactUs;