import React from 'react';

const styles = {
  heroContainer: {
    position: 'relative',
    width: '100vw',
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  
  backgroundContainer: {
    width: '100%',
    maxHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
    maxHeight: '100vh',
    display: 'block',
  },
  
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  
  contentContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end', // Changed to flex-end to align to bottom
    alignItems: 'center',
    padding: '20px',
  },
  
  buttonWrapper: {
    marginBottom: '5vh', // Consistent spacing from bottom using viewport height
    position: 'relative',
  },
  
  button: {
    display: 'inline-block',
    padding: '12px 32px',
    border: '2px solid white',
    color: 'white',
    fontSize: '1.125rem',
    fontWeight: '500',
    letterSpacing: '0.05em',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'all 0.3s ease',
    textTransform: 'uppercase',
  },
};

const HeroSection = ({ 
  buttonText = "VOKIM Launch RSVP Form",
  imageUrl = "static/Band_Banner.jpg"
}) => {
  return (
    <div style={styles.heroContainer}>
      <div style={styles.backgroundContainer}>
        <img 
          src={imageUrl} 
          alt="VOKIM Gospel Band"
          style={styles.image}
        />
        <div style={styles.overlay} />
        <div style={styles.contentContainer}>
          <div style={styles.buttonWrapper}>
            <a 
              href='https://docs.google.com/forms/d/e/1FAIpQLSdW65j92YlVAXPWCnPCsEDxBe5sdukl9m6dqaPoJZMaF34ioA/viewform?usp=pp_url'
              target="_blank"
              rel="noopener noreferrer"
            >
              <button 
                style={styles.button}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = 'white';
                  e.target.style.color = '#4C1D95';
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = 'transparent';
                  e.target.style.color = 'white';
                }}
              >
                {buttonText}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;