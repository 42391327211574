import React from 'react';
import GospelHub  from './Message/message';

const MessageLayout = () => {
  // const imageUrl="https://images.unsplash.com/photo-1527735095040-147bffb4cede?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&q=100"
  return (
    <div>
      <GospelHub/>
    </div>

  );
};

export default MessageLayout;